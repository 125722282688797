<template>
  <v-container class="cryptomation-container">
    <v-row>
      <v-col>
        <v-card class="pa-5 d-flex flex-column justify-center" width="100%">
          <div class="pb-5 text-center">
            <v-icon color="green" size="100">
              mdi-checkbox-marked-circle
            </v-icon>
          </div>

          <p class="headline text-center">{{ $t("auth.signOutSuccess") }}</p>
          <p class="title text-center" v-if="subTitle.length !== 0">
            {{ subTitle }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Signout",

  data: () => ({
    mode: "",
    subTitle: "",
  }),
  methods: {
    goto() {
      this.$router.push({
        path: "/tradingbot/",
      });
    },
    setMode() {
      switch (this.mode) {
        case "reset_password":
          // "Your Password has been successfully reset, please sign in again"
          this.subTitle = this.$t("auth.resetPasswordSuccess");
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.mode = this.$route.query.mode;
    // redirect
    if (this.mode === undefined || this.mode.length === 0) {
      this.mode = "default";
    }
    this.setMode();
  },
};
</script>
<style></style>

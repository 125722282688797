import { httpClientPost } from "@/services/api/axios";
import axios from "axios";

export async function registerAPI(payload) {
  try {
    return await httpClientPost("/user/", payload)
  } catch (error) {
    return {};
  }
}

export async function verifyEmailAPI(oobCode) {
  try {
    let res_data = await axios.post(
      "https://asia-northeast1-crytosystem-4d333.cloudfunctions.net/apiv1/user/verification",
      {
        code: oobCode,
      }
    );
    //
    return { error: false, data: res_data };
  } catch (error) {
    return {
      error: true,
      errorCode: error.response.status,
      msg: error.response.data.message,
    };
  }
}

export async function resetPasswordAPI(oobCode, password) {
  try {
    let res_data = await axios.put(
      "https://asia-northeast1-crytosystem-4d333.cloudfunctions.net/apiv1/user/password/reset",
      {
        code: oobCode,
        password: password,
      }
    );
    //
    return { error: false, data: res_data };
  } catch (error) {
    return {
      error: true,
      errorCode: error.response.status,
      msg: error.response.data.message,
    };
  }
}

<template>
  <v-container class="cryptomation-container">
    <v-row dense>
      <v-col cols="12">
        <h1 class="mb-1">{{ $t("auth.signUpSuccess") }}</h1>
      </v-col>
      <v-col cols="12">
        <v-card class="pa-5 d-flex flex-column justify-center" width="100%">
          <div class="pb-5 text-center">
            <v-icon color="green" size="100">
              mdi-checkbox-marked-circle
            </v-icon>
          </div>

          <p class="headline text-center">{{ $t("auth.checkingYourEmail") }}</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SignupStatus",

  data: () => ({}),
  methods: {},
};
</script>
<style></style>

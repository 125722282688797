<template>
  <v-container class="cryptomation-container">
    <v-row dense v-show="!mainLoading">
      <v-col>
        <h1 class="mb-1 text-center">{{ $t("auth.signUp") }}</h1>
      </v-col>
    </v-row>

    <v-row dense v-show="mainLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>

    <v-row dense v-show="!mainLoading">
      <v-col>
        <v-sheet rounded class="pa-10">
          <v-alert color="red" type="error" v-if="formError">
            {{ messageError }}
          </v-alert>
          <form>
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              :label="$t('form.email')"
              required
              :disabled="formDisabled"
              type="text"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              color="info"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :error-messages="passwordErrors"
              :label="$t('form.password')"
              required
              type="password"
              :disabled="formDisabled"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              color="info"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              :error-messages="confirmPasswordErrors"
              :label="$t('form.confirmpassword')"
              required
              type="password"
              :disabled="formDisabled"
              @input="$v.confirmPassword.$touch()"
              @blur="$v.confirmPassword.$touch()"
              color="info"
            ></v-text-field>
            <v-btn
              class="mt-4"
              :disabled="formDisabled"
              :loading="formDisabled"
              color="success"
              block
              @click="signupAction"
            >
              {{ $t("auth.signUp") }}
            </v-btn>
          </form>
        </v-sheet>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { required, sameAs, minLength, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { registerAPI } from "@/services/api/authen";
import { APP_CONSTANTS } from "../../utils/constants";
export default {
  name: "Signup",
  mixins: [validationMixin],
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      formError: false,
      messageError: "",
      formDisabled: false,
      mainLoading: true,
    };
  },
  mounted() {
    this.checkUserExisting();
  },
  methods: {
    checkUserExisting() {
      if (this.$store.getters.isSessionTokenSet) {
        this.$redictToMySubscription();
      } else {
        this.mainLoading = false;
      }
      // check token
    },
    async signupAction() {
      this.messageError = "";
      this.formError = false;
      this.formDisabled = true;
      if (!this.$v.$invalid) {
        let res_data = await registerAPI({
          email: this.email,
          password: this.password,
        });
        if (res_data["code"] === APP_CONSTANTS.RESPONSE_CODE.SUCCESS) {
          this.$router.push({
            path: "/signupstatus",
          });
        } else if (res_data["code"] === 400) {
          if (res_data["message"] === "EMAIL_EXISTS") {
            this.messageError = this.$t("error.auth.emailAlreadyExists");
          }
          this.formError = true;
        } else {
          this.messageError = this.$t("error.auth.canotConnectServer");
          this.formError = true;
        }
      } else {
        this.$v.$touch();
      }
      this.formDisabled = false;
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("error.auth.emailNotValid"));
      !this.$v.email.required &&
        errors.push(this.$t("error.auth.emailRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push(this.$t("error.auth.passwordLessThanSix"));
      !this.$v.password.required &&
        errors.push(this.$t("error.auth.passworRequired"));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push(this.$t("error.auth.passwordNotMatching"));
      return errors;
    },
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
    confirmPassword: { sameAsPassword: sameAs("password") },
  },
};
</script>
